<template>
  <div>
    <v-tabs v-model="activeTab" centered>
      <v-tab
        v-for="tab of insuredPersonTabs"
        :key="tab.name"
        :to="tab.route"
        @click="setActiveTab(tab.route)"
        exact
      >
        {{ $t(tab.name) }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import { authComputed } from './../store/helpers.js'

export default {
  data() {
    return {
      activeTab: `/insuredPerson/`,
    }
  },
  methods: {
    setActiveTab(route) {
      this.activeTab = route
    },
  },

  computed: {
    ...authComputed,
  },
}
</script>

<style lang="scss" scoped></style>
